const greetingAscii = `
░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒▒▒▒▓▓▓█▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░░░▓▓▓▓▓▓▓▓▓▓█▒░░░░░░░░░░░░░░░░░░░░░█▓▒▒▒▒▒▒▒█░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░▒█▒▒▒▒▒▒▒▒▒▒▒▓█▒░░░░░░░░░░░░░░░░░░░█▓▒▒▒▒▒▒▒█▓▒▓▓▒▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░░█▒▒▒▓▒▒▒▒▒▒▒▒▒▒█▒░░░░░░░░░░▓█▓█░░▓▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒█░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░▒█▒▒▓▓▒▒▒▒▒▒▒▒▒▒█▒░░░░░█▓▓▓▓▓▒▒██▓▒▒▒▒▒▒▓▓▒▒▒▒▒▒▒▒▒█▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░▓▓▒▒▓▓▒▒▒▒▒▒▒▒▒▒▓▓░░░░░█▒▒▒▒▒▒▒▒▓▒▒▒▒▒▒█▒█▒▒▒▒▒▒▒▒▒█▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░░█▒▒▒▒▒▒▒▓█▓▒▒▒▒▒▒▓█░░░░▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▓▒▒▒▒██▓▓█▓░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░░▓▓▒▒▒▒▒▒▒▒██▓▒▒▒▒▒▒▒█░░░▒█▒▒▒▒▒▓▒▒▒▓█▓█▓▒▒▒▒▒▒▒█▓▓▓░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░▒█▒▒▒▒▒▒▒█▓▓░█▒▒▒▒▒▒▒▓▓░░░█▒▒█▓▓▓▒▒▓█░░▓▓▒▒▒▒▒▒▒▓█░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░█▓▒▒▒▒▒▒▓█░░▒▓█▒▒▒▒▒▒▒▓█▒░▓▓▒▓▓▒▒▒▒▓▓░░▓▓▒▒▒▒▒▒▒▒█▓▒▒▒▓▓░░░░░░░░░░░░░░░░░░░░░░░▒▒▒▓▓▓▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░░█▒▒▒▒▒▒▒▓▓▓▓▓▓▒▒▒▓▓▓▒▒▒▒█▒▒█▒▒▒▒▒▒▒▓▓░░░█▒▒▒▒▒▒▒▒▒▒▓▓▓▒▓█░░░░░░░░░░░░░░░░░░░▒█▓▓▒▒▒▒▒▓█░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░▒▓▓▓▓▓▓▓▓▓▒░░░░
░░░░░░░░░░░░░░░░█▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓█▒▓▒▒▒▓██▒▒▒▒▒▒▒▒█░░░▓▓▒▒▒▒▒▓▒▒▒▒▒▒▒▒█▓▓▓▓▒▓▓█▒░░░░░░░░░░▒█▒▒▒▒▒▒▒▒▓▓░░░░░░░░░░░░░░░░░░░░░░░░░░░▒▒░░░░▒▓▓█▒▒▓▓▒▒▒▒▒▒▒▒▒▒█░░░░
░░░░░░░░░░░░░░░▓▓▒▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓░▒▓█▓▒▒▒▒▒▓▓▒▒▒▒▒▒▒▓▒▒▒▒▒▒▓█░░░░░░░░░░░▓▓▒▒▒▒▒▒▒▒█░░░░░░░░░░░▒▒▒░░░░░░░░▒▒░▓▓▓▓█░░█▓▒▒▓██▒▒▒▒▒▒▒▒▒▒▒█▒░░░░
░░░░░░░░░░░░░░▒█▒▒▓▒▓▒▒▒▓▓▓▓▒▒▓▓▓▓▓▒▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▓▒█▓▓▓▓▓▓▓▓▓▓▓▓▓█▒▒▒▒▒▒▒▒▒█░░░░░░░░░░░▓▓▒▒▒▒▒▒▒▒█░░░░░░▒▒▒▓▓▓▓▓▓▓▓▓▒░░▓▓▓▓▒▒▒▒█▓█▒▒▒▒▓█▒▒▒▓▒▒▒▓▓▓▓▓█▓░░░░
░░░░░░░░░░░░░▒█▒▒▒▓▓▓▒▒▓▓▒░░██▓░░░█▒▒▒▒▒▒▒▒▒▒▓▓▓▒▒▒▒▒▒▒▒▒█▒░░░░░░░░░░░░▓▓▒▒▒▒▒▒▒▓█░░▒▓▓▓▓▒▒░░░█▓▒▒▒▒▒▒▒█░░░▓▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒▓█▓█▒▒▒▒▒▒▓█▓▒▒▒▒▒▒▒▒▓▓▒▒█░░░▒▒▒░░░░░
░░░░░░░░░░░░░█▓▒▒▒▒▓▒▒▒█░░░░░░░░░░▒█▓▓▓▓▓▓▓▓▓▒░▒█▒▒▒▒▒▒▒▒█░░░░░░░░░░░░░░█▒▒▒▒▓▒▒▓██▓▒▒▒▒▒▒▓▓▓▒░█▓▒▒▒▒▒▒█▒▒█▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓█▒▒▒▒▒▒▒▒▒▒▒▓▓▓▒▒█▓█▒▒▓▓▓▓▓▓▓▓▒░░░
░░░░░░░░░▒▓▓▓▓▒▒▒▒▒▓█▓▓█░░░░░░░░░░░░░░░░░░░░░░░░█▒▒▒▒▒▒▒▒█░░░▒▓▓▓▓▓▒░░░░█▓▒▒▒█▓▒▒▓▒▒▒▒▒▒▒▒▒▒▒▓█▓▓▒▒▒▒▒▒▓▓█▒▒▒▒▒▒▒▓██▓█▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓░█▓▒▓▓▓▒▒▒▒▒▒▒▒▒▒▓▓▓░
░░░░░░▒▓▓▓▒▒▒▒▒▒▒▒▒▒▓▓█▒░░░░░░░░░░░░░░░░░░░░░░░░█▓▒▒█▒▒▒▒█▒▓▓▓▒▒▒▒▒▓▓▓▓▓▓▓▒▒▒██▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓█▓▒▒▒▒▒▒▓█▓▒▒▒▒▒▒▒▓▓▓█▓▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒█░░░▒▓▓▓▓▓▓▒▒▒▒▒▒▒▓▓▒█▒
░░░░▒█▓▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓█▒░░░░░░░░░░░░░░▓▓▓▓▓▓▓▒░░▒█▒▒█▒▓▒▒██▒▒▒▒▒▒▒▒▒▒▒▒▓█▓▒▒▒▒▒▒▒▒▒▒▓▓▓▒▒▒▒▒▒▒▒▓█▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▒▒▒▒▓▓▒▒▓▓░░░▒▓▓▒▒░▒█▓▒▓▓▒▓▓▓▒▓▓
░░░█▓▒▒▒▒▒▒▒▒▓▓▓▓▒▒▓█░░░░░░░░░░▒▓▓▓▓▓▓▒▒▒▒▒▒▒▒▓█▒█▒▒█▒█▓▒▒▒▒▒▒▒▓▒▒▒▒▒▒▒▒▓█▒▒▒▒▒▒▒▒▓█▒░█▒▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▓▓▓▓▓▓▓▒▓▓░█▓▒▒█▒█▒▒▓▓░░▒█▒▒▒▓▓▓▓▒▒▒█▓█▓▒▒▓▓
░▒█▒▒▒▒▒▒▒▒▓▓▒░░░▒▓▓▒░░░░░░░░▓█▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓█▓▒▒▓▓▒▒▒▓▓█▒▓██▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒█░░░▒▓▒▓▓▒▒▒▒▒▓▒▒▒▓█▒█▒▒▒▒▒▒▒▒▒▒█▒▒▒▒▓▓▒░▒▓▒░▓▓▒▒▓▓█▒▒▓▓░░▒█▒▒▒▒▒▒▒▒▒▒▒▓▒▒▓▓▓░
░█▒▒▒▒▒▒▒▒█▒░░░░▒▒▓▓▓▓▓▓▓▓▓▒█▓▒▒▒▒▒▒▒▒▓▓▓▓▒▒▒▓▒▒▒▓▓▒▒▒▒▒▒▒█▒▓█▓▓░█▒▒▒▒▒▓▒▒▒▒▒▒▒▒▒▒▓▓░░░░░▓▓▒▒▒▒▒█▓▒▒▒█▒█▒▒▓▓▒▒▒▒▒▒▒▓▓▓▓▒▒█▓▓▓▓█▒█▒▒▒▒▒▒▒▒█░░▓▓▒▒▓▓▓▓▓▓▓▓▒▒▒█▒░░░
▓▓▒▒▒▒▒▒▒▓▓░░░▒█▓▒▒▒▒▒▒▒▒▒▓█▓▒▒▒▒▒▒▒▒▒█░░▓▓▒▒█▒▓▒▒▒▒▒▒▒▒▒▒▓▓░░░░░█▒▒█▒▒▓▓▒▓▒▒▒▒▒▒▒▒▓▓▓▓▓▓▓▒▒▒▒▒▒█▓▒▒▒▓▓▓▒▒▓█▓▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒█▒░█▒▒▒▒▒▒▒▒█▒░░▒▓▒▒░▒▒░░░░█▒▒█░░░░
█▓▒▒▒▒▒▒▒▓█░░░▓█▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒█▒██░░░█▒▒█▒█▒▒▓▓▒▒▒▒▒▒▒▓▓▓▓▓█▓▒▒█▒█▓▒▓█▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓██▒▒▒▒▒▒▒▒▒█▓█▓▒▒▒▒▒▒▒▒▒▒▓▓▓▓░░░█▓▓▒▒▒▒▓▓█▒░░░░░░░░░░░░░▒▓▓▒░░░░
▓▓▒▒▓▒▒▒▒▒█▓░░░▒█▓█▓█▒▒▒▒▒▒▒▒▒▒▒▒▒▒█▓░░░░░█▒▒▒▓▒▒▒██▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▓▒▒██▒▒▒▒▒▓▓▓▒▒▒▒▒▒▒▒▒▒▒▓█▒█▒▒▒▒▒▒▒▒▓▓░░░▓█▒▒▒▒▓▒▒▓▓░░░░░░░▒▒█▓▒▓▓░░░░░░░░░░░░░░░░░░░░░░░░
▒█▒▒▓▓▒▒▒▒▒▓▓░░░▓█▓░▓▓▒▒▒▒▒▒▓▒▒▒▒▒▒▒▓█▓▓▓▓▒▒▒▒▒▒▒▓██▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▒▓█▒█▒▓█▓▒█▒░▓▓▓▒▒▒▒▓█▒▓▒░░█▓▒▒▒▒▒▓▓▓░░░░░▒█▒▒█▒▓▓▒░░░░░░░░░░▒█▒▓▓░░░░░░░░░░░░░░░░░░░░░░░░
░█▓▒▒█▒▓▓▒▓▒▓▓▓▒▒▒▒▓▓▒▒▒▒▓▓▒█▓▒▒▒▒▒▒▓▒▒▒▒▒▒▒▒▒▒▓▓▒░█▒▒▒▒▒▒██▓▒▒▒▒▒▒▒▒▓▓▒░░▓▓▓░█▒█▒░░░▒▓▓▓▓▒░░░░░░░▒▓█▓▒▒█░░░░░░░░█▓▓▓░░░░░░░░░░░░░░░░▒▒░░░░░░░░░░░░░░░░░░░░░░░░░
░░█▒▒█▒██▒█▒▒▒▒▒▓▓▓▒▒▒▒▓▓▓▓▒▓██▓▒▒▒▒▓▓▓▓▒▒▓▒▒▓█░░░░▓▓▓▓▒█▒▒░░▒▒▒▒▓▓▒▒█░░░░░░░░▓▓█▒░░░░░░░░░░░░░░░░░░▓▓▒▓▓░░░░░░░░░▒▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░█▓▓▓▓▒▓▓▒▒▒▒▒▒▒▒▒▒▒▒▒▓▓▒▒▓█░░▒▓▓▓▓▓▓▒▒█▒▒▓▒░░░░░░░░█▓█░░░░░░░░░▒▓▓▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░█▓█░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░▒▓▓▓▒▓▓▒▒▒▒▒▒▒▒▒▒▒▓▓▒▒▒▒▒█░░░░░░▒█▓▒█▓░░░░░░░░░░░░░▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░▒▓▒▒█▓▓▓▓▒▓█▓▓▓▓█▒▒▒▒▒█░░░░░░░░▒▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
░░░░░░░░░░░░░░░█▓█░░░░░▓▓▓▓▓▓▒░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░░
                             🧪 🧪 🧪 artgobblers.com - a digital art experiment by @JustinRoiland and @Paradigm - more to come 🧪 🧪 🧪
`

export { greetingAscii }
