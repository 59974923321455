import { useMemo } from 'react'
import {
  css,
  DefaultTheme,
  ThemeProvider as StyledComponentsThemeProvider,
} from 'styled-components'
import { Colors } from './styled'

export const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 900, //960
  upToLarge: 1280,
}

export enum Z_INDEX {
  dropdown = 1000,
  sticky = 1020,
  fixed = 1030,
  modalBackdrop = 1040,
  offcanvas = 1050,
  modal = 1060,
  popover = 1070,
  tooltip = 1080,
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(
  MEDIA_WIDTHS,
).reduce((accumulator, size) => {
  ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
    @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
      ${css(a, b, c)}
    }
  `
  return accumulator
}, {}) as any

const palette = {
  white: '#FFFFFF',
  black: '#080A0C',
  blackPure: '#000000',
  gray: '#5C5C6B',
  lightGray: '#868698',
  lightestGray: '#DCDCE2',
  background: '#F3F3F3',
  purple: '#5656EC',
  pink: '#FF6CBC',
  red: '#FF5F5F',
  lightBlue: '#96D1D9',
  neonGreen: '#8EF42E',
  twitterBlue: '#53C2F2',
}

function colors(darkMode: boolean): Colors {
  return {
    darkMode,
    // base palette
    ...palette,

    // text
    text1: palette.black,

    // backgrounds / greys
    bg0: darkMode ? '#191B1F' : '#FFF',
    bg1: darkMode ? '#212429' : '#F7F8FA',

    //primary colors
    primary1: palette.purple,

    // color text
    primaryText1: palette.black,

    // secondary colors
    secondary1: palette.pink,

    // other
    highlight: palette.neonGreen,
    red1: palette.red,

    // gradients
    juicyGradient: 'linear-gradient(180deg, #9FFF45 0%, #7DE71A 100%)',
    twitterGradient: 'linear-gradient(180deg, #4A99E9 0%, #3386D9 100%)',
    blackGradient: 'linear-gradient(180deg, #000000 0%, #3E4439 100%)',
    yellowGradient: 'linear-gradient(180deg, #FFF614 0%, #FFBD14 100%)',
    lightBlueGradient: 'linear-gradient(180deg, #D0F5FF 0%, #84D4E9 100%)',
    linearGradient: 'linear-gradient(180deg, #788186 0%, #5B656B 100%)',
  }
}

function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24,
    },

    // shadows
    // TODO(johnrjj) - shadows from figma
    shadow1: '#000',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `,
  }
}

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = false

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return (
    <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
  )
}
