import type { AppProps } from 'next/app'
import NextNProgress from 'nextjs-progressbar'
import { SSRProvider } from '@react-aria/ssr'
import { DefaultSeo } from 'next-seo'
import styled from 'styled-components'
import { useRouter } from 'next/router'
import { SessionProvider } from 'next-auth/react'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { Provider as UrqlProvider } from 'urql'
import { Toaster } from 'react-hot-toast'
import { trpc } from '~/utils/trpc'
import {
  RainbowKitProvider,
  getDefaultWallets,
  connectorsForWallets,
  darkTheme,
  lightTheme,
} from '@rainbow-me/rainbowkit'
import {
  injectedWallet,
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  argentWallet,
  trustWallet,
  ledgerWallet,
} from '@rainbow-me/rainbowkit/wallets'
import { mainnet, goerli } from 'wagmi/chains'
import { createClient, WagmiConfig, configureChains } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { ThemeProvider } from '../styles/theme'
import { CSSBaseGlobalStyle } from '../styles/base'
import { CSSResetsGlobalStyle } from '../styles/resets'
import { DEFAULT_SITE_TITLE, DEFAULT_SITE_DESCRIPTION, DEFAULT_SITE_NAME } from '../seo'
import { DEFAULT_CHAIN_ID, ROOT_URL_PRODUCTION, TWITTER_HANDLE } from '../config'
import { Header } from '../components/core/header/Header'
import { CSSGlobalTypography } from '../styles/typography'
import useWindowSize from '../components/draw/hooks/useWindowSize'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-medium-image-zoom/dist/styles.css'
import '@rainbow-me/rainbowkit/styles.css'
import '../styles/globals.css'
import '../styles/fonts.css'

import { SSRContext } from '~/utils/trpc'
import { loggerLink } from '@trpc/client/links/loggerLink'
import { withTRPC } from '@trpc/next'
import { httpBatchLink } from '@trpc/client/links/httpBatchLink'
import { httpLink } from '@trpc/client/links/httpLink'
import { splitLink } from '@trpc/client/links/splitLink'
import { AppRouter } from '~/server/routers/_app'
// import '@dotlottie/player-component';
import superjson from 'superjson'

import { getGqlClient } from '../web3/subgraph'
import { useEffect, useState } from 'react'
import { OverlayProvider } from '@react-aria/overlays'
import { greetingAscii } from 'utils/ascii'
import Script from 'next/script'
import { cdnRoot } from 'utils/cdn'

import {
  GetSiweMessageOptions,
  RainbowKitSiweNextAuthProvider,
} from '@rainbow-me/rainbowkit-siwe-next-auth'
import '@rainbow-me/rainbowkit/styles.css'

const getSiweMessageOptions: GetSiweMessageOptions = () => ({
  statement:
    'Log in to Art Gobblers.\n\nBy signing this message you agree to the Art Gobblers terms and coditions: artgobblers.com/terms',
})

// if (process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID) {
//   datadogRum.init({
//     applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
//     clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN_RUM ?? '',
//     site: 'datadoghq.com',
//     service: 'art-gobblers-frontend',
//     env: process.env.NEXT_PUBLIC_VERCEL_ENV,
//     version: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
//     sampleRate: 100,
//     premiumSampleRate: 100,
//     trackInteractions: true,
//     defaultPrivacyLevel: 'allow',
//   })

//   datadogRum.startSessionReplayRecording()
// }

// if (process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN_LOGGER) {
//   datadogLogs.init({
//     clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN_LOGGER,
//     site: 'datadoghq.com',
//     forwardErrorsToLogs: true,
//     sampleRate: 100,
//   })
// }

// const rinkebyRpcUrl = 'https://eth-rinkeby.alchemyapi.io/v2/DcXDChayBMAqA6G-UQZslnMBOOyZxyRV'

// const jsonRpcs = jsonRpcProvider({
//   rpc: (chainId) => {
//     return {
//       http: rinkebyRpcUrl,
//     }
//   },
// })
import { GobblerAuthEffect } from 'hooks/useGobblerAuthEffect'
import Brb from './z/brb'
;(global as any).cdnRoot = cdnRoot

// TODO(johnrjj) - Add private frontend alchemy RPC link for Goerli and mainnet
const { chains, provider, webSocketProvider } = configureChains(
  [mainnet, goerli],

  [alchemyProvider({ apiKey: 'NEP5hwzYokgGkQzjMtCncefDg2ECi3BP' }), publicProvider()],
)

const { wallets } = getDefaultWallets({
  appName: 'Art Gobblers',
  chains,
})

const connectors = connectorsForWallets([
  ...wallets,
  {
    groupName: 'Other',
    wallets: [argentWallet({ chains }), trustWallet({ chains }), ledgerWallet({ chains })],
  },
])

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider,
})

let alertedLock = false

function ArtGobblersApp({
  Component,
  pageProps,
}: AppProps<{
  // TODO(johnrjj) - Type this
  dehydratedState: unknown
  session: any // Session from types.d.ts, having trouble importing it
}>) {
  const [queryClient] = useState(() => new QueryClient())

  const size = useWindowSize()
  const router = useRouter()

  if (router.pathname === '/draw' && size.width <= 700 && !alertedLock) {
    alertedLock = true
    toast.warning('Your screen is a bit small for the demo! Try turning your device sideways.')
  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      console.log(greetingAscii)
    }
  }, [])

  const lightModeRoutes = [
    '/about',
    '/drawing',
    '/user',
    '/draw',
    '/drafts',
    '/faq',
    '/gallery',
    '/terms',
    // '/contributors',
    '/raffle',
    '/gobbler/',
    // '/page/',
    '/user/',
    '/404',
  ]

  const drawModeRoutes = [
    // '/about',
    // '/drawing',
    '/draw',
    // '/drafts',
    // '/raffle',
    // '/404',
    // '/contributors',
    // '/faq',
  ]

  const darkModeOverrides = ['/gobbler/mint', '/page/mint']

  let isDrawMode = drawModeRoutes.some((route) => router.pathname.startsWith(route))

  let isLightMode =
    lightModeRoutes.some((route) => router.pathname.startsWith(route)) || router.pathname === '/'

  // Since we filter light mode by 'startsWith()' we need to do an additional check for conflicting dark mode paths
  if (darkModeOverrides.some((route) => router.pathname.startsWith(route))) {
    isLightMode = false
    isDrawMode = false
  }

  const hideMiddleNav = isDrawMode

  return (
    <>
      <ToastContainer limit={1} pauseOnHover={false} style={{ fontWeight: 500 }} />
      <DefaultSeo
        title={DEFAULT_SITE_TITLE}
        description={DEFAULT_SITE_DESCRIPTION}
        openGraph={{
          type: 'website',
          locale: 'en_US',
          url: ROOT_URL_PRODUCTION,
          title: DEFAULT_SITE_TITLE,
          description: DEFAULT_SITE_DESCRIPTION,
          site_name: DEFAULT_SITE_NAME,
          images: [
            {
              url: `https://cdn.artgobblers.com/web/images/og/title-social-banner.png`,
              width: 983,
              height: 490,
              alt: 'Art Gobblers',
            },
          ],
        }}
        twitter={{
          handle: TWITTER_HANDLE,
          site: TWITTER_HANDLE,
          cardType: 'summary_large_image',
        }}
        additionalMetaTags={[
          {
            name: 'twitter:image',
            content: `https://cdn.artgobblers.com/web/images/og/title-social-banner.png`,
          },
          {
            name: 'twitter:url',
            content: ROOT_URL_PRODUCTION,
          },
        ]}
      />
      <Script
        strategy="beforeInteractive"
        id={'createjs'}
        src="https://cdn.artgobblers.com/web/vendor/createjs.min.js"
      />
      <SSRProvider>
        <ThemeProvider>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <CSSResetsGlobalStyle />
              <CSSBaseGlobalStyle />
              <CSSGlobalTypography />

              <WagmiConfig client={wagmiClient}>
                <SessionProvider session={pageProps.session} refetchInterval={0}>
                  <RainbowKitSiweNextAuthProvider getSiweMessageOptions={getSiweMessageOptions}>
                    <RainbowKitProvider
                      theme={lightTheme({
                        overlayBlur: 'small',
                        fontStack: 'system',
                      })}
                      // HACK(johnrjj) - Temporarily disable dark theme, will add light vs dark theme detection soon.
                      // theme={darkTheme({
                      //   overlayBlur: 'small',
                      //   fontStack: 'system',
                      // })}
                      appInfo={{
                        appName: 'Art Gobblers',
                        learnMoreUrl: 'https://artgobblers.com/faq',
                      }}
                      chains={chains}
                    >
                      <UrqlProvider value={getGqlClient(DEFAULT_CHAIN_ID)}>
                        <OverlayProvider>
                          <Toaster
                            position="bottom-right"
                            reverseOrder={false}
                            gutter={8}
                            containerClassName=""
                            containerStyle={{}}
                            toastOptions={{
                              // Define default options,
                              className: 'test-toast',
                              // duration: 5000,
                              style: {
                                lineHeight: '150%',
                                // fontFamily; 'favorit-regular',
                                fontSize: 16,
                                background: '#131417',
                                border: '1px solid #C7BADE',
                                color: '#fff',
                                paddingTop: 12,
                                paddingBottom: 12,
                                marginBottom: 32,
                                boxShadow: `0.3px 0.3px 2.2px rgba(0, 0, 0, 0.02), 0.7px 0.8px 5.3px rgba(0, 0, 0, 0.028),
                      1.3px 1.5px 10px rgba(0, 0, 0, 0.035), 2.2px 2.7px 17.9px rgba(0, 0, 0, 0.042),
                      4.2px 5px 33.4px rgba(0, 0, 0, 0.05), 10px 12px 80px rgba(0, 0, 0, 0.07)`,
                              },
                              // Default options for specific types
                              loading: {
                                duration: 1000 * 60,
                              },
                              success: {
                                duration: 10000,
                                theme: {
                                  primary: 'green',
                                  secondary: 'black',
                                },
                              },
                            }}
                          />

                          <NextNProgress
                            // color="#5656EC"
                            color="#F441F0"
                            startPosition={0.3}
                            stopDelayMs={200}
                            height={2}
                            showOnShallow={true}
                          />
                          <>
                            <GobblerAuthEffect />
                          </>

                          {console.log(router.pathname)}
                          {router.pathname != '/draw/[drawingId]' && (
                            <Header lightMode={isLightMode} isDrawHeader={hideMiddleNav} />
                          )}

                          <Component {...pageProps} />
                        </OverlayProvider>
                      </UrqlProvider>
                    </RainbowKitProvider>
                  </RainbowKitSiweNextAuthProvider>
                </SessionProvider>
              </WagmiConfig>
            </Hydrate>
            <ReactQueryDevtools />
          </QueryClientProvider>
        </ThemeProvider>
      </SSRProvider>
    </>
  )
}

const Mobile = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`
const MobileText = styled.div`
  max-width: 80vw;
`

const Tablet = styled.div``

function getBaseApiUrl() {
  // Special endpoint/server just for saving drawings (w/ save() fn in routers/drawings)
  if (process.env.MAIN_API_ROOT_URL || process.env.NEXT_PUBLIC_MAIN_API_ROOT_URL) {
    return process.env.MAIN_API_ROOT_URL || process.env.NEXT_PUBLIC_MAIN_API_ROOT_URL
  }
  if (typeof window !== 'undefined') {
    return ''
  }
  // reference for vercel.com
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`
  }

  // // reference for render.com
  if (process.env.RENDER_INTERNAL_HOSTNAME) {
    return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`
}

function getBaseDrawSaveApiUrl() {
  // Special endpoint/server just for saving drawings (w/ save() fn in routers/drawings)
  if (
    process.env.DRAW_SERVER_API_ROOT_URL_FOR_SAVING_PRIMARY ||
    process.env.NEXT_PUBLIC_DRAW_SERVER_API_ROOT_URL_FOR_SAVING_PRIMARY
  ) {
    return (
      process.env.DRAW_SERVER_API_ROOT_URL_FOR_SAVING_PRIMARY ||
      process.env.NEXT_PUBLIC_DRAW_SERVER_API_ROOT_URL_FOR_SAVING_PRIMARY
    )
  }
  // Otherwise fall back to our default url lookup flow
  return getBaseApiUrl()
}

const trpcMiddleware = withTRPC<AppRouter>({
  config({ ctx }) {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    return {
      headers: () => {
        if (ctx?.req) {
          // on ssr, forward client's headers to the server
          return {
            ...ctx.req.headers,
            'x-ssr': '1',
          }
        }
        return {}
      },
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: 'include',
        })
      },
      /**
       * @link https://trpc.io/docs/links
       */
      links: [
        // TODO(johnrjj) - Support multiple endpoints so we can move heavy processes to another server...
        // https://github.com/trpc/trpc/discussions/1990#discussioncomment-2949666
        // https://github.com/trpc/trpc/blob/e37b1ca34b2e5ecce1b016cd82dc19e882e3710e/examples/soa/src/client.ts#L21-L47
        // https://github.com/trpc/trpc/blob/e37b1ca34b2e5ecce1b016cd82dc19e882e3710e/examples/soa/src/server-_combined.ts#L6-L9
        // // create a custom ending link
        // (runtime) => {
        //   // initialize the different links for different targets
        //   const servers = {
        //     server1: httpBatchLink({ url: 'http://localhost:2031' })(runtime),
        //     server2: httpBatchLink({ url: 'http://localhost:2032' })(runtime),
        //   };
        //   return (ctx) => {
        //     const { op } = ctx;
        //     // split the path by `.` as the first part will signify the server target name
        //     const pathParts = op.path.split('.');

        //     // first part of the query should be `server1` or `server2`
        //     const serverName =
        //       pathParts.shift() as string as keyof typeof servers;

        //     // combine the rest of the parts of the paths
        //     // -- this is what we're actually calling the target server with
        //     const path = pathParts.join('.');
        //     console.log(`calling ${serverName} on path ${path}`);

        //     const link = servers[serverName];

        //     link({
        //       ...ctx,
        //       op: {
        //         ...op,
        //         // override the target path with the prefix removed
        //         path,
        //       },
        //     });
        //   };
        // },
        // adds pretty logs to your console in development and logs errors in production
        loggerLink({
          enabled: (opts) =>
            process.env.NODE_ENV === 'development' ||
            (opts.direction === 'down' && opts.result instanceof Error),
        }),
        // https://trpc.io/docs/links#2-perform-request-without-batching
        splitLink({
          condition(op) {
            const shouldUseSaveEndpoint =
              op.context.useDedicatedSaveDrawingServer === true || op.path === 'drawing.save'
            return shouldUseSaveEndpoint
          },
          // when condition is true, use special save server (defaults to normal defaults if no special server endpoint is provided)
          true: httpLink({
            url: `${getBaseDrawSaveApiUrl()}/api/trpc`,
          }),
          // when condition is false, use normal built-in nextjs endpoint server
          false: splitLink({
            condition(op) {
              // check for context property `skipBatch`
              const shouldSkipBatchingForRequest = op.context.skipBatch === true
              return shouldSkipBatchingForRequest
            },
            // when condition is true, use normal request
            true: httpLink({
              url: `${getBaseApiUrl()}/api/trpc`,
            }),
            // when condition is false, use batching
            false: httpBatchLink({
              url: `${getBaseApiUrl()}/api/trpc`,
              maxBatchSize: 5,
            }),
          }),
        }),
      ],
      /**
       * @link https://trpc.io/docs/data-transformers
       */
      transformer: superjson,
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
    }
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: true,
  /**
   * Set headers or status code when doing SSR
   */

  responseMeta(opts) {
    const ctx = opts.ctx as SSRContext

    if (ctx.status) {
      // If HTTP status set, propagate that
      return {
        status: ctx.status,
      }
    }

    const error = opts.clientErrors[0]
    if (error) {
      // Propagate http first error from API calls
      return {
        status: error.data?.httpStatus ?? 500,
      }
    }
    // For app caching with SSR see https://trpc.io/docs/caching
    return {}
  },
})

export default trpcMiddleware(ArtGobblersApp)
