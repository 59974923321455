import { useButton } from '@react-aria/button'
import { mergeRefs } from '@react-aria/utils'
import { animated, useSpring } from '@react-spring/web'
import { AriaButtonProps } from '@react-types/button'
import React, { useRef, useState, forwardRef } from 'react'
import styled, { CSSProperties, useTheme } from 'styled-components'

// Button w/ full CSS resets
const CssResetButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
`

// Minimum Viable Button
const BaseButton = styled(CssResetButton)`
  position: relative;
  z-index: 0;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  will-change: transform;
  line-height: 105%;
`

interface ButtonProps {
  elementType?: 'button' | 'div'
  isDisabled?: boolean
  key?: string
  style?: CSSProperties
  // ref?: React.MutableRefObject<HTMLButtonElement | null>
}

const PrimaryButtonProgressContainerBottom = styled.div`
  position: absolute;
  z-index: 2;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  border-radius: 100px;
  overflow: hidden;
  /* box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.25); */
`

const PrimaryButtonProgressContainerTop = styled.div`
  position: absolute;
  z-index: 3;
  top: 0px;
  bottom: 0px;
  left: 0;
  right: 0;
  will-change: transform;
  transform: translate3d(0, 0, 0);
  background-color: #dae4ea;
`

const AnimatedPrimaryButtonProgressContainerTop = animated(PrimaryButtonProgressContainerTop)

type ButtonInteractionState = 'hovering' | 'pressed' | 'idle'

const getButtonScaleFromInteractionState = (state: ButtonInteractionState) => {
  if (state === 'pressed') {
    return 0.96
  }
  if (state === 'hovering') {
    return 0.99
  }
  if (state === 'idle') {
    return 1
  }
}

interface PrimaryButtonProps {
  percentProgressOutOf100?: number
  buttonType?: 'primary' | 'primary_white' | 'secondary' | 'success' | 'twitter'
  buttonSize?: 'normal' | 'small'
  buttonTextStyles?: React.CSSProperties | undefined
  as?: 'button' | 'a'
  href?: string
  target?: '_blank'
  rel?: 'noopener noreferrer'
}

const PrimaryButtonButton = styled(BaseButton)<PrimaryButtonProps>`
  position: relative;
  z-index: 0;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => (props.buttonSize === 'small' ? '18px' : '20px')};

  width: 100%;
  height: 72px;
  background: #080a0c;
  height: ${(props) => (props.buttonSize === 'small' ? '56px' : ' 72px')};
  border-radius: ${(props) => (props.buttonSize === 'small' ? '12px' : '100px')};
  border: ${(props) => (props.buttonSize === 'small' ? 3 : 4)}px solid #080a0c;

  text-decoration: none;
  outline: none;

  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.2), 0px 12px 16px rgba(0, 0, 0, 0.16),
    0px 4px 4px rgba(0, 0, 0, 0.12), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
`

const PrimaryButtonText = styled.span`
  position: relative;
  color: #8ef42e;
  text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.32);
  z-index: 5;

  user-select: none;
  transform: translate3d(0, 0, 0);
  will-change: transform;
  line-height: 100%;
  padding: 0 8px;
`

const IconButtonButton = styled(PrimaryButtonButton)`
  position: relative;
  height: 56px;
  width: 56px;
  min-width: 56px;
  max-width: 56px;
  border-radius: 100%;
  background: #080a0c;
  /* Basic/Black */
  border: 3px solid #080a0c;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.1), 0px 12px 16px rgba(0, 0, 0, 0.08),
    0px 4px 4px rgba(0, 0, 0, 0.06), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;

  will-change: transform;
  outline: none;
  overflow: hidden;
`

const IconButtonSheen = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 24px;
  height: 24px;
  left: 16px;
  top: 16px;
  width: 100%;
  height: 24px;
  width: 24px;
  filter: drop-shadow(-1px -3px 4px rgba(255, 255, 255, 0.2))
    drop-shadow(0px 16px 32px rgba(27, 26, 37, 0.24))
    drop-shadow(0px 12px 24px rgba(27, 26, 37, 0.16))
    drop-shadow(0px 4px 8px rgba(27, 26, 37, 0.12));
  border-radius: 40%;
`

const AnimatedIconButtonButton = animated(IconButtonButton)

const AnimatedPrimaryButtonButton = animated(PrimaryButtonButton)

const AnimatedPrimaryButtonText = animated(PrimaryButtonText)

const PrimaryButton: React.FC<PrimaryButtonProps & ButtonProps & AriaButtonProps<'button'>> = (
  props,
) => {
  const ref = useRef<HTMLButtonElement | null>(null)
  const { buttonProps, isPressed } = useButton(props, ref)

  const [isHovering, setIsHovering] = useState<boolean>(false)

  const theme = useTheme()

  const currentState = isPressed ? 'pressed' : isHovering ? 'hovering' : 'idle'

  let currentBgColor = isHovering ? '#1a1a1c' : '#080A0C'
  let borderColor = '#080A0C'

  if (props.buttonType === 'secondary') {
    currentBgColor = theme.white
    borderColor = theme.white
  }
  if (props.buttonType === 'success') {
    currentBgColor = '#8EF42E' // neon green
  }
  if (props.buttonType === 'twitter') {
    if (isHovering) {
      currentBgColor = '#75CEF5'
    } else {
      currentBgColor = theme.twitterBlue
    }
  }

  let progressPercent = props.percentProgressOutOf100 ?? 100
  let scale: number | undefined = getButtonScaleFromInteractionState(currentState)

  let boxShadow =
    '0px 24px 32px rgba(0, 0, 0, 0.1), 0px 12px 16px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.06), inset 0px 4px 4px rgba(255, 255, 255, 0.25)'

  const isLoading = false
  // eventually isLoading...
  if (isLoading) {
    borderColor = '#ffffff'
    scale = 1
    boxShadow =
      '0px 24px 32px rgba(0, 0, 0, 0.2), 0px 12px 16px rgba(0, 0, 0, 0.16), 0px 4px 4px rgba(0, 0, 0, 0.12), inset 0px 4px 4px rgba(255, 255, 255, 0.25)'
  }

  if (props.isDisabled) {
    currentBgColor = '#595A63'
    borderColor = '#26262B'
    // textColor = '#B8BCCF'
    scale = 1
    boxShadow =
      '0px 0px 0px rgba(27, 26, 37, 0.24), 0px 0px 0px rgba(27, 26, 37, 0.16), 0px 0px 0px rgba(27, 26, 37, 0.12), inset 0px 0px 0px rgba(255, 255, 255, 0.44)'
  }

  if (props.buttonSize === 'small') {
    boxShadow = `0px 24px 32px rgba(0, 0, 0, 0.2), 0px 12px 16px rgba(0, 0, 0, 0.16), 0px 4px 4px rgba(0, 0, 0, 0.12), inset 0px 4px 4px rgba(255, 255, 255, 0.25)`
  }

  if (props.buttonType === 'twitter') {
    borderColor = '#177FAB'
  }

  const cardBackgroundSpring = useSpring({
    config: { mass: 1, tension: 500, friction: 40 },
    scale: scale,
    backgroundColor: currentBgColor,
    boxShadow,
    borderColor,
  })

  const progressSpring = useSpring({
    config: { mass: 1, tension: 500, friction: 40 },
    x: progressPercent + 1,
  })

  let textShadow = props.isDisabled
    ? '0px -1px 1px rgba(255, 255, 255, 0.44)'
    : '0px 1px 4px rgba(0, 0, 0, 0.32)'
  let textColor = props.isDisabled ? theme.lightestGray : '#8EF42E'
  if (props.buttonType === 'secondary') {
    textColor = theme.black
    textShadow = '0px 0px 0px rgba(0, 0, 0, 0)'
  }
  if (props.buttonType === 'success') {
    textColor = theme.black
    textShadow = '0px 0px 0px rgba(0, 0, 0, 0)'
  }
  if (props.buttonType === 'twitter') {
    textColor = theme.white
  }

  if (props.buttonType === 'primary_white') {
    textColor = theme.white
  }

  const textSpring = useSpring({
    color: textColor,
    textShadow: textShadow,
  })

  return (
    <AnimatedPrimaryButtonButton
      key={props.key}
      isDisabled={props.isDisabled}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onPointerEnter={() => setIsHovering(true)}
      onPointerLeave={() => setIsHovering(false)}
      buttonSize={props.buttonSize}
      as={props.as ?? undefined}
      href={props.href}
      target={props.target}
      {...buttonProps}
      isPressed={isPressed}
      ref={ref}
      style={{
        ...cardBackgroundSpring,
        ...props.style,
      }}
    >
      <PrimaryButtonProgressContainerBottom>
        <AnimatedPrimaryButtonProgressContainerTop
          style={{ transform: progressSpring.x.to((x) => `translate3d(${x}%, 0, 0)`) }}
        />
      </PrimaryButtonProgressContainerBottom>

      <AnimatedPrimaryButtonText style={{ ...textSpring, ...props.buttonTextStyles }}>
        {props.children}
      </AnimatedPrimaryButtonText>
    </AnimatedPrimaryButtonButton>
  )
}

interface IconButtonProps {
  as?: 'button' | 'a'
  href?: string
  target?: '_blank'
  rel?: 'noopener noreferrer'
  ref?: any
  active?: boolean
  size?: number
  // ref?: React.RefObject<HTMLButtonElement>
}

const IconButton: React.FC<IconButtonProps & ButtonProps & AriaButtonProps<'button'>> = forwardRef(
  (props, externalRef) => {
    const internalRef = useRef<HTMLButtonElement | null>(null)
    const { buttonProps, isPressed } = useButton(props, internalRef)

    const [isHovering, setIsHovering] = useState<boolean>(false)

    const currentState = isPressed ? 'pressed' : isHovering ? 'hovering' : 'idle'

    const theme = useTheme()
    let scale: number | undefined = getButtonScaleFromInteractionState(currentState)

    const cardBackgroundSpring = useSpring({
      config: { mass: 1, tension: 500, friction: 40 },
      scale: scale,
      background: props.active
        ? '#8EF42E'
        : props.isDisabled
        ? '#2F3035'
        : isHovering
        ? '#2F3035'
        : '#080A0C',
      borderColor: props.isDisabled ? '#26262B' : '#080A0C',
    })

    return (
      <AnimatedIconButtonButton
        {...props}
        key={props.key}
        isDisabled={props.isDisabled}
        as={props.as ?? undefined}
        href={props.href}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onPointerEnter={() => setIsHovering(true)}
        onPointerLeave={() => setIsHovering(false)}
        {...buttonProps}
        isPressed={isPressed}
        ref={mergeRefs(internalRef, externalRef)}
        style={{
          height: props.size ?? undefined,
          width: props.size ?? undefined,
          minWidth: props.size ?? undefined,
          minHeight: props.size ?? undefined,
          ...cardBackgroundSpring,
          ...props.style,
        }}
      >
        <IconButtonSheen />
        {/* {React.cloneElement(props.children, { loggedIn: loggedIn })} */}
        {props.children}
      </AnimatedIconButtonButton>
    )
  },
)

IconButton.displayName = 'IconButton'

export { PrimaryButton, IconButton, BaseButton }
