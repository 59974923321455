const CDN_ROOT = `https://cdn.artgobblers.com`

const getStaticResourcePathRoot = () => {
  return `${CDN_ROOT}/web`

  if (process.env.NODE_ENV === 'production') {
    return `${CDN_ROOT}/web`
  }
  return ''
}

const getStaticResourceUrl = (resourcePath: string) => {
  return `${getStaticResourcePathRoot()}${resourcePath}`
}

let cdnRootBuildTime = getStaticResourcePathRoot()

export { getStaticResourcePathRoot, getStaticResourceUrl, cdnRootBuildTime as cdnRoot }
