import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useAccount } from 'wagmi'
import { trpc } from '~/utils/trpc'
import { useGobblerAuth } from './useGobblerAuth'

interface Session {
  // 'twitter' or 'ethereum
  provider: string
  // Wallet address or next-auth id if twitter/discord oauth
  id: string
  address?: string
  twitter_id?: string
  discord_id?: string
  twitter_username?: string

  gobblerAuth?: {
    id: string
    twitter_internal_auth_id: string | null
    twitter_name: string | null
    twitter_id: string | null
    twitter_handle: string | null
    wallet_internal_auth_id: string | null
    wallet_address: string | null
    wallet_ens_name: null
    avatar_img_src: null
    description: null
  }
}

const GobblerAuthEffect = () => {
  const router = useRouter()

  const accountData = useAccount()

  const { data: session, status } = useSession()

  const typedSession: Session | null = session as Session | null

  const query = router.query

  const maybeCode = query.code
  const maybePathname = query.pathname
  const gobblerAuth = useGobblerAuth()

  const linkTwitterToAccountMutation = trpc.useMutation(['user.linkTwitterToAccount'])

  const userQuery = gobblerAuth.userQuery
  const handleSignOut = gobblerAuth.handleSignOut

  // Log out any old auth
  useEffect(() => {
    if (!typedSession?.gobblerAuth?.id && typedSession?.id) {
      handleSignOut()
    }
  }, [handleSignOut, typedSession?.gobblerAuth?.id, typedSession?.id])

  // Effects
  useEffect(() => {
    if (!maybeCode) {
      return
    }
    linkTwitterToAccountMutation
      .mutateAsync({
        code: maybeCode.toString(),
      })
      .then((x) => {
        userQuery.refetch()
        router.push({
          pathname: (maybePathname?.toString() as any) ?? '/', // TODO(johnrjj) - Current pathname without query params
          query: {},
        })
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maybeCode, maybePathname, router])

  useEffect(() => {
    userQuery.refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session, session?.address, session?.id])

  useEffect(() => {
    if (!accountData.address) {
      return
    }
    if (!userQuery.data?.wallet_address) {
      return
    }
    if (accountData.address.toLowerCase() !== userQuery.data.wallet_address.toLowerCase()) {
      handleSignOut()
    }
  }, [accountData.address, handleSignOut, userQuery.data?.wallet_address])

  return null
}

export { GobblerAuthEffect }
