import { animated, config, useSpring } from '@react-spring/web'
import styled from 'styled-components'
import useMeasure from 'react-use-measure'
import { useRef } from 'react'
import { createUseGesture, moveAction, scrollAction } from '@use-gesture/react'
import React from 'react'
import { cdnRoot } from 'utils/cdn'

// import { useRef } from 'react'
const useGesture = createUseGesture([moveAction, scrollAction])

const AbsoluteContainer = styled.div`
  position: absolute;
`

const AnimatedAbsoluteContainer = animated(AbsoluteContainer)

const RelativeContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
`

interface EyeProps {
  size: number
  containerStyle?: React.CSSProperties
}

const Eye: React.FC<EyeProps> = (props) => {
  const [eyeMeasureRef, eyeBoundary] = useMeasure()

  const [pupilStyle, pupilStyleApi] = useSpring(() => ({
    x: 0,
    y: 0,
    scale: 1,
    rotateZ: 0,
    config: config.stiff,
  }))

  const scrollXRef = useRef<number>(0)
  const scrollYRef = useRef<number>(0)
  const mouseXRef = useRef<number>(0)
  const mouseYRef = useRef<number>(0)

  const computeDistance = () => {
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth

    const eyeballX = eyeBoundary.x
    const eyeballY = eyeBoundary.y

    const eyeballSizeWidth = eyeBoundary.width
    const eyeballSizeHeight = eyeBoundary.height

    const scrollX = scrollXRef.current
    const scrollY = scrollYRef.current
    const mouseX = mouseXRef.current
    const mouseY = mouseYRef.current

    const allowedTravelOfPupilInPixelX = eyeballSizeWidth / 2
    const allowedTravelOfPupilInPixelY = eyeballSizeHeight / 2

    const eyeballCenterX = eyeballX + eyeballSizeWidth / 2
    const eyeballCenterY = eyeballY + eyeballSizeHeight / 2

    // negative x is left, positive x is right
    const mousePositionRelativeToEyeX = mouseX - eyeballCenterX - scrollX
    // negative y is down, positive y is up
    const mousePositionRelativeToEyeY = eyeballCenterY - mouseY - scrollY

    const distanceFromEyeBallCenterToTopOfPage = eyeballCenterY
    const distanceFromEyeBallCenterToBottomOfPage = windowHeight - eyeballCenterX
    const ratioTraveledToTop = mousePositionRelativeToEyeY / distanceFromEyeBallCenterToTopOfPage
    const ratioTraveledToBottom =
      mousePositionRelativeToEyeY / distanceFromEyeBallCenterToBottomOfPage

    const distanceFromEyeBallCenterToLeftOfPage = eyeballCenterX
    const distanceFromEyeBallCenterToRightOfPage = windowWidth - eyeballCenterX
    const ratioTraveledToLeft = mousePositionRelativeToEyeX / distanceFromEyeBallCenterToLeftOfPage
    const ratioTraveledToRight =
      mousePositionRelativeToEyeX / distanceFromEyeBallCenterToRightOfPage

    let ratioX = 0
    if (mousePositionRelativeToEyeX >= 0) {
      ratioX = ratioTraveledToRight
    } else {
      ratioX = ratioTraveledToLeft
    }

    let ratioY = 0
    if (mousePositionRelativeToEyeY >= 0) {
      ratioY = -ratioTraveledToTop
    } else {
      ratioY = -ratioTraveledToBottom
    }

    const scaledMovementX = ratioX * (allowedTravelOfPupilInPixelX / 5.5) // 3.5 magic number manually tuned (higher number is less travel?)
    const scaledMovementY = ratioY * (allowedTravelOfPupilInPixelY / 7.5) // 4.5 magic number manually tuned

    pupilStyleApi.start({
      x: scaledMovementX,
      y: scaledMovementY,
    })
  }

  useGesture(
    {
      onMove: (mv) => {
        const [mouseX, mouseY] = mv.xy
        mouseXRef.current = mouseX
        mouseYRef.current = mouseY
        computeDistance()
      },
      onScroll: (scrollData) => {
        const [scrollX, scrollY] = scrollData.xy
        scrollXRef.current = scrollX
        scrollYRef.current = scrollY
        computeDistance()
      },
      onTouchStart: (mv) => {
        const { clientX, clientY } = mv.event.touches[0]
        mouseXRef.current = clientX
        mouseYRef.current = clientY
        computeDistance()
      },
    },
    {
      target: global.window,
    },
  )

  return (
    <div className={'eye'}>
      <RelativeContainer ref={eyeMeasureRef} style={{ ...props.containerStyle }}>
        <AnimatedAbsoluteContainer>
          <img src={`${cdnRoot}/images/eye/eye-white.png`} />
        </AnimatedAbsoluteContainer>
        <AnimatedAbsoluteContainer style={pupilStyle}>
          <img src={`${cdnRoot}/images/eye/eye-pupil.png`} />
        </AnimatedAbsoluteContainer>
        <AbsoluteContainer>
          <img src={`${cdnRoot}/images/eye/blink-with-delay.gif`} />
        </AbsoluteContainer>
        <img src={`${cdnRoot}/images/eye/eye-outer.png`} />
      </RelativeContainer>
    </div>
  )
}

const EyeMemo = React.memo(Eye)

export { EyeMemo as Eye }
