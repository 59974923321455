import { createGlobalStyle } from 'styled-components'

const CSSGlobalTypography = createGlobalStyle`
    h1 {
        font-family: Matter;
        font-style: normal;
        font-weight: 900;
        font-size: 64px;
        line-height: 100%;
        letter-spacing: -0.03em;
    }

    h2 {
        font-family: Matter;
        font-style: normal;
        font-weight: 900;
        font-size: 48px;
        line-height: 100%;
        letter-spacing: -0.03em;
    }

    h3 {
        font-family: Matter;
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        line-height: 100%;
        letter-spacing: -0.03em;
    }

    h4 {
        font-family: Matter;
        font-style: normal;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: -0.03em;
    }

    h5 {
        font-family: Matter;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 100%;
        letter-spacing: -0.03em;
    }

    p {
        font-family: Matter;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 23px;
        letter-spacing: -0.03em;
    }

    h1,h2,h3,h4,h5,p {
        color: #ffffff;
    }

    textarea:not([autosave="false"]) {
        background: #FFFFFF;
        box-sizing: border-box;
        outline: none;
        padding: 20px;
        width: 100%;

        box-shadow: 0px 16px 24px rgba(18, 18, 23, 0.08), 0px 2px 6px rgba(18, 18, 23, 0.02), inset 4px 8px 16px rgba(255, 255, 255, 0.32);
        border-radius: 24px;

        resize: none;

        height: 120px;
        border: 3px solid #CAD4DA;

        transition: all 0.25s ease-in-out;

        :hover {
            border: 3px solid #868698;
            box-sizing: border-box;
            border-radius: 20px;
        }

        :active,:focus {
            border: 3px solid #080A0C;
            box-sizing: border-box;
            border-radius: 20px;
        }
    }
`

export { CSSGlobalTypography }
