import styled from 'styled-components'
import { useMenuTriggerState } from '@react-stately/menu'
import { useButton } from '@react-aria/button'
import {
  AriaMenuItemProps,
  AriaMenuOptions,
  useMenu,
  useMenuItem,
  useMenuTrigger,
} from '@react-aria/menu'
import { useTreeState } from '@react-stately/tree'
import { mergeProps } from '@react-aria/utils'
import { FocusScope } from '@react-aria/focus'
import { useFocus } from '@react-aria/interactions'
import { useOverlay, DismissButton } from '@react-aria/overlays'
import { MenuTriggerProps } from '@react-types/menu'
import { BaseButton } from '../Buttons'
import { useTransition, animated } from '@react-spring/web'
import { useRef, useState } from 'react'
import { CollectionChildren } from '@react-types/shared'
import { cdnRoot } from 'utils/cdn'

interface MenuButtonProps extends MenuTriggerProps {
  label: React.ReactNode
  domProps?: any
  children?: React.ReactNode
  onAction?: (key: string | number) => void
}

const MenuButtonButton = styled(BaseButton)`
  height: 44px;
  width: 44px;
  border-radius: 50%;
  max-width: 44px;
  max-height: 44px;
  overflow: hidden;
  user-select: none;
  border: 2px solid black;

  filter: drop-shadow(0px -2px 4px rgba(255, 255, 255, 0.24))
    drop-shadow(0px 8px 16px rgba(27, 26, 37, 0.12))
    drop-shadow(0px 4px 12px rgba(27, 26, 37, 0.08));

  &:hover {
    cursor: pointer;
  }
`

const ProfileImage = styled.img`
  object-fit: contain;
  height: 100%;
  width: 100%;
`

function UserAvatarMenuButon(props: MenuButtonProps) {
  // Create state based on the incoming props
  let state = useMenuTriggerState(props)

  // Get props for the menu trigger and menu elements
  let ref = useRef<HTMLButtonElement>(null)
  let { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref)

  // Get props for the button based on the trigger props from useMenuTrigger
  let { buttonProps } = useButton(menuTriggerProps, ref)

  const isOpen = state.isOpen

  const transitions = useTransition(isOpen, {
    from: {
      opacity: 0,
      y: 10,
    },
    enter: {
      opacity: 1,
      y: 0,
    },
    leave: {
      opacity: 0,
      y: 10,
    },
    key: isOpen,
  })

  return (
    <div style={{ position: 'relative' }}>
      <MenuButtonButton id={'menu-button'} key={'menu-button'} {...buttonProps} ref={ref}>
        {/* TODO(johnrjj) - Thread through props */}
        <ProfileImage src={`${cdnRoot}/images/gobbler-avatar.png`} />
      </MenuButtonButton>

      {transitions((styles, item) => {
        return (
          item && (
            <MenuPopup
              key={'menu'}
              {...(props as any)}
              {...menuProps}
              springProps={styles}
              autoFocus={state.focusStrategy}
              onClose={() => state.close()}
              onAction={props.onAction}
            />
          )
        )
      })}
    </div>
  )
}

export interface MenuPopupProps<T extends object> extends AriaMenuOptions<T> {
  onClose: () => void
  domProps?: any
  springProps?: any
  children: CollectionChildren<T>
}

const MenuListContainer = styled.ul`
  background: #ffffff;
  border: 1px solid #efeff5;
  box-sizing: border-box;
  box-shadow: 0px 28px 48px rgba(0, 0, 0, 0.04), 0px 16px 16px rgba(0, 0, 0, 0.02),
    0px 8px 8px rgba(0, 0, 0, 0.02);
  border-radius: 36px;
`

const AnimatedMenuListContainer = animated(MenuListContainer)

function MenuPopup(props: MenuPopupProps<{}>) {
  // Create menu state based on the incoming props

  let state = useTreeState({ ...props, selectionMode: 'none' })

  // Get props for the menu element
  let ref = useRef<HTMLUListElement>(null)
  let { menuProps } = useMenu(props, state, ref)

  // Handle events that should cause the menu to close,
  // e.g. blur, clicking outside, or pressing the escape key.
  let overlayRef = useRef<HTMLDivElement>(null)
  let { overlayProps } = useOverlay(
    {
      onClose: props.onClose,
      shouldCloseOnBlur: true,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef,
  )

  // Wrap in <FocusScope> so that focus is restored back to the
  // trigger when the menu is closed. In addition, add hidden
  // <DismissButton> components at the start and end of the list
  // to allow screen reader users to dismiss the popup easily.
  return (
    <FocusScope restoreFocus>
      <div {...overlayProps} ref={overlayRef}>
        <DismissButton onDismiss={props.onClose} />
        <AnimatedMenuListContainer
          {...mergeProps(menuProps, props.domProps)}
          ref={ref}
          style={{
            position: 'absolute',
            top: 'calc(100% + 12px)',
            width: '100%',
            right: 0,
            padding: '24px 16px',
            minWidth: 340,
            listStyle: 'none',
            userSelect: 'none',
            ...props.springProps,
          }}
        >
          {[...state.collection].map((item) => (
            <MenuItem
              key={item.key}
              item={item}
              state={state}
              onAction={props.onAction}
              onClose={props.onClose}
            />
          ))}
        </AnimatedMenuListContainer>
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  )
}

export interface MenuItemProps {
  'state': any
  'item': AriaMenuItemProps

  /** Whether the menu item is disabled. */
  'isDisabled'?: boolean
  /** Whether the menu item is selected. */
  'isSelected'?: boolean
  /** A screen reader only label for the menu item. */
  'aria-label'?: string
  /** The unique key for the menu item. */
  'key'?: number | string
  /** Handler that is called when the menu should close after selecting an item. */
  'onClose'?: () => void
  /**
   * Whether the menu should close when the menu item is selected.
   * @default true
   */
  'closeOnSelect'?: boolean
  /** Whether the menu item is contained in a virtual scrolling menu. */
  'isVirtualized'?: boolean
  /** Handler that is called when the user activates the item. */
  'onAction'?: (key: string | number) => void
}

const MenuItemStyled = styled.li`
  background: #f5f5f7;
  border-radius: 19px;
  padding: 8px 8px 8px 10px;
  min-height: 58px;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
`

const MenuItem = (props: MenuItemProps) => {
  // Get props for the menu item element
  let ref = useRef<HTMLLIElement>(null)
  let { menuItemProps } = useMenuItem(
    {
      key: props.item.key,
      isDisabled: props.item.isDisabled,
      onAction: props.onAction,
      onClose: props.onClose,
    },
    props.state,
    ref,
  )

  // Handle focus events so we can apply highlighted
  // style to the focused menu item
  let [isFocused, setFocused] = useState(false)
  let { focusProps } = useFocus({ onFocusChange: setFocused })

  return (
    <MenuItemStyled
      {...mergeProps(menuItemProps, focusProps)}
      ref={ref}
      style={{
        background: isFocused ? '#F5F5F7' : 'transparent',
        color: isFocused ? '#121217' : '#121217',
        outline: 'none',
        cursor: 'pointer',
      }}
    >
      {(props.item as any).rendered}
    </MenuItemStyled>
  )
}

export { UserAvatarMenuButon }
