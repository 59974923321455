import styled from 'styled-components'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useAccount } from 'wagmi'

import { ANoUnderline } from '../Links'
import { P } from '../Typography'
import { Eye } from '../Eye'

import HeaderConnectButton from './HeaderConnectButton'
import { useIsMounted } from 'hooks/util/useIsMounted'
import { HeaderMobileFooter, HeaderMark } from './HeaderMobileFooter'
import useIsMobile from 'hooks/util/useIsMobile'

const HeaderOuter = styled.div<{ isMintPage?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  margin-left: 32px;
  color: #080a0c;
  & * {
    color: #080a0c;
  }
  margin-right: 32px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-left: 8px;
    margin-right: 8px;

    ${(props) =>
      // @ts-ignore
      props.isMintPage &&
      `
      background: rgba(47, 48, 53, 0.6);
      margin: 0;
      width: 100%; 
       
    `};

  `}

  margin-top: 20px;
  flex-direction: row;
  background-color: transparent;
  align-items: center;
  justify-content: center;
  z-index: 10;
`

const HeaderSpacerNoMobile = styled.div`
  height: 136px;
  min-height: 136px;
  max-height: 136px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    height: 100px;
    min-height: 100px;
    max-height: 100px;
  `}
`

const HeaderSpacer = styled.div`
  height: 136px;
  min-height: 136px;
  max-height: 136px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  height: 120px;
  min-height: 120px;
  max-height: 120px; 
  `}
`

const StyledANoUnderline = styled(ANoUnderline)<{
  isActive?: boolean
  lightMode?: boolean
}>`
  padding: 0 20px;
`

const StyledAWithGlow = styled(StyledANoUnderline)`
  height: 100%;
  padding: 0;
  display: flex;

  flex-direction: column;
  justify-content: space-between;

  transition: transform 0.125s ease-in-out;

  &:hover {
    transform: scale(1.035);
  }

  /* width: 120px; */

  margin: 0 16px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 0;
  `}
`

const GlowDiv = styled.div`
  width: 100%;
  height: 4px;

  will-change: transform;

  transition: all 0.25s ease-in-out;
  background-image: linear-gradient(
    to right,
    rgba(131, 255, 86, 0),
    rgba(131, 255, 86, 0.65),
    rgba(131, 255, 86, 0.85),
    rgba(131, 255, 86, 1),
    rgba(131, 255, 86, 0.85),
    rgba(131, 255, 86, 0.65),
    rgba(131, 255, 86, 0)
  );
`

const HoverHeaderContainer = styled.div<{
  lightMode: boolean
  shouldBlurBg: boolean
  isMintPage?: boolean
}>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  border-radius: 60px;
  max-width: 1280px;
  height: 72px;
  width: 100%;

  will-change: transform;
  transform: translate3d(0, 0, 0);
  background: ${(props) => (props.lightMode ? `rgba(255, 255, 255, 0.24)` : `rgba(0, 0, 0, 0.24)`)};

  &::-webkit-scrollbar {
    display: none;
  }

  ${(props) =>
    props.shouldBlurBg &&
    `
    backdrop-filter: blur(5px);
  `};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    background: transparent;


    ${(props) =>
      // @ts-ignore
      props.isMintPage &&
      `
    border-radius: 0px; 
    padding-top: 48px;
    padding-bottom: 48px;
    `}; 
  `}
`

const HoverHeaderLeftContainer = styled.div`
  display: flex;
  gap: 8px;
  z-index: 10;
  align-items: center;
`

const HoverHeaderRightContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  width: fit-content;
`

const HoverHeaderMiddleContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0 auto;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  height: 100%;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-left: -24px;
`}

  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`

const MiddleRoute = ({
  pathname,
  text,
  href,
  lightMode,
}: {
  text: string
  pathname: string
  lightMode: boolean
  href?: {
    // TODO(johnrjj) - Fix routing typings, should be Route imported from nextjs-routes
    pathname: string
    query?: { [key: string]: string | string[] | undefined }
  }
}) => {
  const { asPath } = useRouter()

  const isActive = pathname === asPath

  return (
    <Link passHref href={href || { pathname }}>
      <StyledAWithGlow>
        <div />
        <HeaderMark lightMode={lightMode}>{text}</HeaderMark>
        <GlowDiv style={{ opacity: isActive ? 1 : 0, transform: `scale(${isActive ? 1.1 : 0})` }} />
      </StyledAWithGlow>
    </Link>
  )
}

const MiddleRoutes = ({ lightMode }: { lightMode: boolean }) => {
  const account = useAccount()

  const walletConnected = account.isConnected

  return (
    <HoverHeaderMiddleContainer>
      {walletConnected && (
        <MiddleRoute lightMode={lightMode} pathname="/dashboard" text="Dashboard" />
      )}
      {walletConnected && <MiddleRoute lightMode={lightMode} pathname="/factory" text="Factory" />}
      <MiddleRoute lightMode={lightMode} pathname="/gallery" text="Gallery" />
      {!walletConnected && (
        <>
          <MiddleRoute lightMode={lightMode} pathname="/faq" text="FAQ" />
          <MiddleRoute lightMode={lightMode} pathname="/greenpaper" text="Green Paper" />
        </>
      )}

      {/* {account && <MiddleRoute lightMode={lightMode} pathname="/drafts" text="Drafts" />} */}
    </HoverHeaderMiddleContainer>
  )
}

const Header = ({
  lightMode = true,
  isDrawHeader,
}: {
  lightMode?: boolean
  isDrawHeader?: boolean
}) => {
  const mounted = useIsMounted()

  const { isConnected } = useAccount()

  const router = useRouter()
  const { exportMode } = router.query

  const isMobile = useIsMobile()

  if (exportMode) {
    return null
  }

  if (router.pathname.includes('/view')) {
    return null
  }

  let headerTitle = ''
  if (router.pathname.startsWith('/drawing/')) {
    headerTitle = ''
  } else if (router.pathname.startsWith('/about')) {
    headerTitle = 'About'
  }

  const isHome =
    router.pathname === '/' || router.pathname === '/z' || router.pathname === '/gobbler/mint'

  const isMintPage = router.pathname === '/gobbler/mint'
  const showMiddleRoutes = !isDrawHeader && mounted

  return (
    <>
      <HeaderOuter isMintPage={isMintPage}>
        <HoverHeaderContainer isMintPage={isMintPage} lightMode={lightMode} shouldBlurBg={isHome}>
          <HoverHeaderLeftContainer>
            <Link passHref href={{ pathname: '/' }}>
              <StyledANoUnderline>
                <div style={{ borderRadius: '100%' }}>
                  <Eye containerStyle={{ left: -34 }} size={44} />
                </div>
              </StyledANoUnderline>
            </Link>
            <P style={{ fontWeight: 600, left: -64, position: 'relative' }}>{headerTitle}</P>
          </HoverHeaderLeftContainer>
          {showMiddleRoutes && <MiddleRoutes lightMode={lightMode} />}
          <HoverHeaderRightContainer>
            <HeaderConnectButton
              showDrawButton={isMobile ? false : true}
              isDrawHeader={isDrawHeader}
            />
          </HoverHeaderRightContainer>
        </HoverHeaderContainer>
      </HeaderOuter>
      {showMiddleRoutes && !isMintPage && <HeaderMobileFooter lightMode={lightMode} />}
    </>
  )
}

export { Header, HeaderSpacer, HeaderSpacerNoMobile }
