import { DEFAULT_CHAIN_ID, getSubgraphAddress } from 'config'
import { Client, createClient } from 'urql'

let _gqlClientSingleton: Client | undefined = undefined

const getGqlClient = (instanceId: number | string) => {
  if (!_gqlClientSingleton) {
    const subgraphUrl = process.env.NEXT_PUBLIC_SUBGRAPH_URL ?? getSubgraphAddress(DEFAULT_CHAIN_ID)
    if (!subgraphUrl) {
      throw new Error('Subgraph url not defined')
    }
    _gqlClientSingleton = createClient({
      // https://thegraph.com/studio/subgraph/bbtest/
      // Deploy id: QmbL6pncxJjnwVG8P8zk3hVYcseYa9EkkARtRDkLVkR5QU
      url: subgraphUrl,
    })
  }
  return _gqlClientSingleton
}
export { getGqlClient }
