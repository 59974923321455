import styled from 'styled-components'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ANoUnderline } from '../Links'
import { useAccount } from 'wagmi'

const black = '#080A0C'

function FooterMiddleRoute({
  pathname,
  text,
  lightMode,
}: {
  text: string
  pathname: any
  lightMode: boolean
}) {
  const { asPath } = useRouter()

  const isActive = pathname === asPath

  return (
    <Link passHref href={pathname}>
      <MobileStyledANoUnderline lightMode={lightMode} isActive={isActive}>
        <HeaderMark lightMode={lightMode}>{text}</HeaderMark>
      </MobileStyledANoUnderline>
    </Link>
  )
}

function HeaderMobileFooter(props: { lightMode: boolean }) {
  const lightMode = props.lightMode
  const account = useAccount()

  const walletConnected = account.isConnected

  return (
    <HeaderMobileFooterContainer lightMode={lightMode}>
      {walletConnected && (
        <FooterMiddleRoute lightMode={lightMode} pathname="/dashboard" text="Dashboard" />
      )}
      {walletConnected && (
        <FooterMiddleRoute lightMode={lightMode} pathname="/factory" text="Factory" />
      )}
      <FooterMiddleRoute lightMode={lightMode} pathname="/gallery" text="Gallery" />
      {!walletConnected && (
        <>
          <FooterMiddleRoute lightMode={lightMode} pathname="/faq" text="FAQ" />
          <FooterMiddleRoute lightMode={lightMode} pathname="/greenpaper" text="Green Paper" />
        </>
      )}
    </HeaderMobileFooterContainer>
  )
}

const HeaderMobileFooterContainer = styled.div<{
  lightMode: boolean
}>`
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 24px;
  left: 20px;

  // background: #f8ffc0;
  background: ${(props) => (props.lightMode ? `#f8ffc0` : `#26262B`)};

  height: 60px;
  width: calc(100% - 40px);

  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.06), 0px 12px 16px rgba(0, 0, 0, 0.04),
    0px 4px 4px rgba(0, 0, 0, 0.03), inset 0px 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 90px;
  z-index: 100000;

  padding: 6px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      display: flex;
    `}
`

const MobileStyledANoUnderline = styled(ANoUnderline)<{
  isActive?: boolean
  lightMode?: boolean
}>`
  padding: 0 20px;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding:  0 2px;
    width: 33%; 
    border-radius: 30px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    
    > div {
      color: ${(props) =>
        // @ts-ignore
        props.isActive ? (props.lightMode ? black : `#8EF42E`) : `${black}`};
    }

    background-color: ${(props) =>
      // @ts-ignore
      props.isActive ? (props.lightMode ? '#F3FF8D' : black) : 'transparent'};
  
    `}
`

const HeaderMark = styled.div<{ lightMode: boolean }>`
  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: ${(props) => (props.lightMode ? black : '#ffffff')};
  text-align: center;
  /* Increase hitbox */
  padding: 8px 8px;
`

export { HeaderMobileFooter, HeaderMark }
