import styled from 'styled-components'

const PBoldDontUse = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
`

const H1Response = styled.h1``

const P = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
`

const P2 = styled(P)`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 130%;
`

const PBold = styled(P)`
  font-weight: 700;
`

const P1Bold = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
`

const PDesktop = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
`

const ResponsiveP = styled(PDesktop)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 16px;
    line-height: 130%;
  `}
`

const PMobile = styled(PDesktop)`
  font-size: 16px;
  line-height: 130%;
`

const H2Mobile = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 100%;
`

const H2Responsive = styled.h2`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 100%;
  `}
`

const H3Responsive = styled.h3`
  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  `}
`

const H4Responsive = styled.h4`
  ${({ theme }) => theme.mediaWidth.upToMedium`
font-weight: 700;
font-size: 16px;
  `}
`

const H5Responsive = styled.h4`
  ${({ theme }) => theme.mediaWidth.upToMedium`

font-size: 14px;
  `}
`

const PSmall = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
`

const PResponsive = styled.p`
  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  `}
`

const PCardTitle = styled.p`
  ${({ theme }) => theme.mediaWidth.upToMedium`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  `}
`

const PBoldResponsive = styled(PBold)`
  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 14px;
    line-height: 17px;
  `}
`

const Label = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
`

const LabelResponsive = styled(Label)``

const H1ResponsiveWithShadow = styled(H1Response)`
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.32);
`

const H2ResponsiveWithShadow = styled(H2Responsive)`
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.32);
`

const Mono1Glow = styled.div`
  font-family: 'MatterMono';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */

  display: flex;
  align-items: center;
  letter-spacing: -0.03em;

  /* White */

  color: #ffffff;

  text-shadow: 0px 0px 14px rgba(131, 255, 86, 0.8);
`

const Mono3Glow = styled.div`
  font-family: 'MatterMono';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  color: #ffffff;

  text-shadow: 0px 0px 14px rgba(13, 255, 86, 0.8);
`

export {
  H1Response,
  H1ResponsiveWithShadow,
  H2ResponsiveWithShadow,
  H2Mobile,
  H4Responsive,
  H5Responsive,
  PBoldDontUse as PBoldDontUse,
  P,
  P2,
  ResponsiveP,
  PSmall,
  H2Responsive,
  H3Responsive,
  PResponsive,
  PDesktop,
  PBoldResponsive,
  LabelResponsive,
  Mono1Glow,
  Mono3Glow,

  // Reskins P's
  PCardTitle,
  P1Bold,
}
