import type { BigNumberish } from '@ethersproject/bignumber'
import { CHAIN_IDS } from './web3/chains'

const ROOT_URL_PRODUCTION = 'https://artgobblers.com'
const TWITTER_HANDLE = '@artgobblers'

export interface GameAddresses {
  ART_GOBBLERS: string
  GOO: string
  PAGES: string
}

const ADDRESSES_GOERLI_WITH_FINAL_MINTLIST: GameAddresses = {
  GOO: '0xcd1fc2aaf8491871e948197cab33f28072536739',
  ART_GOBBLERS: '0xedc5773b1979fc5592f3122cf9b730c1d93c6b16',
  PAGES: '0x1ce654ef8935f7c5e12eb076c9963204229b331a',
}

const ADDRESSES_MAINNET_WITH_FINAL_MINTLIST: GameAddresses = {
  GOO: '0x600000000a36f3cd48407e35eb7c5c910dc1f7a8',
  ART_GOBBLERS: '0x60bb1e2aa1c9acafb4d34f71585d7e959f387769',
  PAGES: '0x600df00d3e42f885249902606383ecdcb65f2e02',
}

const ADDRESSES_GOERLI_STARTED_NO_MINTS: GameAddresses = {
  GOO: '0x35581e9c6f03f26b44d23a2454ad7dd82525b933',
  ART_GOBBLERS: '0x430a5901851fd523715e84c705e10a591c6cea92',
  PAGES: '0xfae17af6c7e07205a93eceb03428e46903bb4f30',
}

const ADDRESSES_GOERLI_MINT_DATE_IN_FUTURE: GameAddresses = {
  GOO: '0x383049bb3ab2300580f2e51887d18efd8a2a00d3',
  ART_GOBBLERS: '0xe82b082ba29e7a75298dccfb7c1431c980e9f0c7',
  PAGES: '0xee0d6a3c924cf38c076592af7ae3066389cdba34',
}

const getAddressesForGobblerInstance = (chainId: BigNumberish): GameAddresses => {
  if (parseInt(chainId.toString(), 10) === CHAIN_IDS.GOERLI) {
    return ADDRESSES_GOERLI_WITH_FINAL_MINTLIST
  }
  return ADDRESSES_MAINNET_WITH_FINAL_MINTLIST
}

const DEFAULT_MINT_START_UNIX_TIMESTAMP_MAINNET = 1667247600

const SUBGRAPH_API_ROOT_URL_GOERLI =
  'https://api.studio.thegraph.com/query/30338/art-gobblers-testnet-subgraph/v0.5.0'

const SUBGRAPH_API_ROOT_URL_MAINNET = `https://api.studio.thegraph.com/query/37321/art-gobblers-mainnet/v0.0.1`

const getSubgraphAddress = (chainId: BigNumberish) => {
  if (parseInt(chainId.toString(), 10) === CHAIN_IDS.GOERLI) {
    return SUBGRAPH_API_ROOT_URL_GOERLI
  }
  return SUBGRAPH_API_ROOT_URL_MAINNET
}

const DEFAULT_CHAIN_ID = CHAIN_IDS.MAINNET.toString()
const DEFAULT_CHAIN_ID_NUMBER = parseInt(DEFAULT_CHAIN_ID, 10)

const SIWE_CONFIG = {
  statement: 'Welcome to Art Gobblers. Please sign in.\nGobble gobble.',
  version: '1',
}

const GCP_PUBLIC_BUCKET_ROOT_URL = 'https://storage.googleapis.com'

const BUCKET_NAMES = {
  PAGES_BUCKET_NAME: 'pages.artgobblers.com', // 'pages-testnet.artgobblers.com',
  GOBBLERS_BUCKET_NAME: 'gobblers.artgobblers.com', // 'gobblers-testnet.artgobblers.com',
  GOBBLER_GENERATED_GIFS_BUCKET_NAME: 'gobbler-gifs.artgobblers.com',
}

const BUCKET_PATH_NAMES = {
  GIFS: 'gifs', // OG gobbler gif
  GIFS_ARTWORK_STATIC: 'gobbler_gifs_artwork_static', // Gobbler gif with artwork image in the belly
  GIFS_ARTWORK_GIF: 'gobbler_gifs_artwork_gif', // Gobbler gif with artwork gif in the belly
  STATIC_IMAGES: 'gobbler_static_images', // OG image of the gobbler
  PROFILE_PICTURES: 'gobbler_profile_pictures', // Cropped PFP of the gobbler
}

const DEFAULT_BLANK_PAGE_IMAGE = `${GCP_PUBLIC_BUCKET_ROOT_URL}/${BUCKET_NAMES.PAGES_BUCKET_NAME}/blank.gif`

const DEFAULT_UNREVEALED_GOBBLER_IMAGE = `${GCP_PUBLIC_BUCKET_ROOT_URL}/${BUCKET_NAMES.GOBBLERS_BUCKET_NAME}/unrevealed.gif`

const DEFAULT_PLAYER_SIZE_IN_PX = 660

const DEFAULT_UNTITLED_ARTWORK = 'Untitled Artwork'

const DEFAULT_ANONMYOUS_NAME = 'Anon'

const DEFAULT_PLAYBACK_SPEED_IN_MS = 60

export {
  ROOT_URL_PRODUCTION,
  TWITTER_HANDLE,
  GCP_PUBLIC_BUCKET_ROOT_URL,
  DEFAULT_MINT_START_UNIX_TIMESTAMP_MAINNET,
  getAddressesForGobblerInstance,
  getSubgraphAddress,
  DEFAULT_CHAIN_ID,
  DEFAULT_CHAIN_ID_NUMBER,
  DEFAULT_BLANK_PAGE_IMAGE,
  DEFAULT_UNREVEALED_GOBBLER_IMAGE,
  SIWE_CONFIG,
  BUCKET_NAMES,
  BUCKET_PATH_NAMES,
  DEFAULT_PLAYER_SIZE_IN_PX,
  DEFAULT_PLAYBACK_SPEED_IN_MS,
  DEFAULT_UNTITLED_ARTWORK,
  DEFAULT_ANONMYOUS_NAME,
}

// Mainnet smart contract wallet config!
const coldWallet = '0xE974159205528502237758439da8c4dcc03D3023'
const communityWallet = '0xDf2aAeead21Cf2BFF3965E858332aC8c8364E991'
const root = '0xae49de097f1b61ff3ff428b660ddf98b6a8f64ed0f9b665709b13d3721b79405'
const gobblerBaseUri = 'https://nfts.artgobblers.com/api/gobblers/'
const gobblerUnrevealedUri = 'https://nfts.artgobblers.com/api/gobblers/unrevealed'
const pagesBaseUri = 'https://nfts.artgobblers.com/api/pages/'
const provenance = '628f3ac523165f5cf33334938a6211f0065ce6dc20a095d5274c34df8504d6e4'
const governorMultiSig = '0x2719E6FdDd9E33c077866dAc6bcdC40eB54cD4f7'
