import { useCallback, useEffect, useMemo } from 'react'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import { useRouter } from 'next/router'
import { stringify } from 'query-string'
import { getCsrfToken, signIn, signOut, useSession } from 'next-auth/react'
import { useAccount, useDisconnect, useNetwork, useSignMessage } from 'wagmi'
import { SiweMessage } from 'siwe'
import { trpc } from '~/utils/trpc'
import { SIWE_CONFIG } from 'config'

interface GobblerAuthOnSession {
  id: string
  twitter_internal_auth_id: string | null
  twitter_name: string | null
  twitter_id: string | null
  twitter_handle: string | null
  wallet_internal_auth_id: string | null
  wallet_address: string | null
  wallet_ens_name: null
  avatar_img_src: null
  description: null
}

const useGobblerAuth = () => {
  const { signMessageAsync } = useSignMessage()
  const { chain: activeChain } = useNetwork()
  const accountData = useAccount()

  const { openConnectModal } = useConnectModal()

  const router = useRouter()

  const { data: session, status } = useSession()
  const loading = status === 'loading'

  const maybeGobblerSession = session?.gobblerAuth as GobblerAuthOnSession | null

  const userQuery = trpc.useQuery(['user.me', {}])

  const hasWalletLinked = !!session && !!userQuery.data?.wallet_address
  const hasTwitterLinked = !!session && !!userQuery.data?.twitter_id

  // NOTE(johnrjj) - Wallets can be 'connected' to the frontend but unauthenticated.
  // Authentication with a wallet is a two-step process (connect wallet, then sign message)
  const isWalletConnected = accountData.isConnected
  const isWalletAuthed = !!userQuery.data?.wallet_address
  const isTwitterConnectedAndAuthed = hasTwitterLinked

  const canLinkTwitterToWallet = hasWalletLinked && !hasTwitterLinked
  const canLinkWalletToTwitter = hasTwitterLinked && !hasWalletLinked

  const canSignOut = !!session

  const disconnectActions = useDisconnect()

  const handleSignOut = useCallback(async () => {
    const signOutRes = await signOut({
      redirect: false,
    })

    await disconnectActions.disconnectAsync()

    const updatedMe = await userQuery.refetch()
    return updatedMe
  }, [disconnectActions, userQuery])

  const connectWallet = useCallback(() => {
    return openConnectModal?.()
  }, [openConnectModal])

  const signInWithWallet = useCallback(async () => {
    if (!accountData?.address) {
      connectWallet()
      return
    }
    const walletAddress = accountData.address
    const csrfToken = await getCsrfToken()
    try {
      const callbackUrl = '/'
      const message = new SiweMessage({
        domain: window.location.host,
        address: walletAddress,
        statement: SIWE_CONFIG.statement,
        uri: window.location.origin,
        version: SIWE_CONFIG.version,
        chainId: activeChain?.id,
        nonce: csrfToken,
      })
      const signature = await signMessageAsync({
        message: message.prepareMessage(),
      })
      const signInResponse = await signIn('credentials', {
        message: JSON.stringify(message),
        redirect: false,
        signature,
        callbackUrl,
      })
      return signInResponse
    } catch (error) {
      window.alert(error)
    }
  }, [accountData.address, activeChain?.id, connectWallet, signMessageAsync])

  const prepareToLinkTwitterToAccountMutation = trpc.useMutation([
    'user.prepareToLinkTwitterToAccount',
  ])
  const linkTwitterToAccountMutation = trpc.useMutation(['user.linkTwitterToAccount'])
  const linkWalletToTwitterMutation = trpc.useMutation('user.linkWalletToTwitter')

  const signInWithTwitter = useCallback((callbackUrl: string) => {
    signIn('twitter', {
      callbackUrl: callbackUrl,
      redirect: false,
    })
  }, [])

  const linkTwitterToWallet = useCallback(async (callbackUrlRoot: string) => {
    const { code } = await prepareToLinkTwitterToAccountMutation.mutateAsync({})

    const queryParams = stringify({
      code,
      pathname: router.pathname,
    })

    signIn('twitter', {
      callbackUrl: `${callbackUrlRoot}?${queryParams}`, // TODO(johnrjj) - Support query params as input
      redirect: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const linkWalletToTwitter = useCallback(async () => {
    // TODO(johnrjj) - Should do this check through userQuery instead?
    if (!maybeGobblerSession?.twitter_id) {
      throw new Error('no twitter account connected/detected')
    }

    const message = new SiweMessage({
      domain: window.location.host,
      address: accountData?.address,
      statement: SIWE_CONFIG.statement,
      uri: window.location.origin,
      version: SIWE_CONFIG.version,
      chainId: activeChain?.id,
      nonce: '1029384756',
    })
    const signature = await signMessageAsync({
      message: message.prepareMessage(),
    })

    const serializedSiweMessage = JSON.stringify(message)

    const result = await linkWalletToTwitterMutation.mutateAsync({
      siweMessage: serializedSiweMessage,
      signature,
    })
    const updatedMe = await userQuery.refetch()
    // return updatedMe
    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountData?.address, activeChain?.id, maybeGobblerSession?.twitter_id, signMessageAsync])

  const isAdmin = userQuery.data?.is_admin

  const authResult = useMemo(() => {
    return {
      // Actions
      handleSignOut,
      connectWallet,
      signInWithWallet,
      signInWithTwitter,
      linkWalletToTwitter,
      linkTwitterToWallet,

      // User
      userQuery: userQuery,
      user: userQuery.data,
      id: userQuery.data?.id,

      // Convenience status checks
      hasTwitterLinked,
      hasWalletLinked,
      isAdmin,
      isWalletConnected,
      isWalletAuthed,
      isTwitterConnectedAndAuthed,
      canLinkTwitterToWallet,
      canLinkWalletToTwitter,
      canSignOut,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleSignOut,
    connectWallet,
    signInWithWallet,
    signInWithTwitter,
    linkWalletToTwitter,
    linkTwitterToWallet,
    hasTwitterLinked,
    hasWalletLinked,
    isWalletConnected,
    isWalletAuthed,
    isTwitterConnectedAndAuthed,
    canLinkTwitterToWallet,
    canLinkWalletToTwitter,
    canSignOut,
    isAdmin,
  ])

  return authResult
}

export { useGobblerAuth }
