import React, { Fragment } from 'react'
import Link from 'next/link'
import styled from 'styled-components'

import { useDisconnect } from 'wagmi'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import { Item } from '@react-stately/collections'

import { PrimaryButton } from '../Buttons'
import { P, PDesktop } from '../Typography'
import { getShortenedAddress } from '../../../web3/chains'
import { UserAvatarMenuButon } from './HeaderMenu'
import { useRouter } from 'next/router'
import { useIsMounted } from 'hooks/util/useIsMounted'
import { useGobblerAuth } from 'hooks/useGobblerAuth'
import useIsMobile from 'hooks/util/useIsMobile'

function HeaderConnectButton({
  isDrawHeader,
  showDrawButton,
}: {
  isDrawHeader?: boolean
  showDrawButton?: boolean
}) {
  const router = useRouter()
  const {
    signInWithWallet,
    handleSignOut,
    isTwitterConnectedAndAuthed,
    signInWithTwitter,
    canLinkTwitterToWallet,
    canLinkWalletToTwitter,
    linkWalletToTwitter,
    linkTwitterToWallet,
    user,
  } = useGobblerAuth()

  const mounted = useIsMounted()
  if (!mounted) {
    return null
  }

  const handleLinkTwitter = () => {
    if (!user) {
      signInWithTwitter(router.asPath)
    } else if (canLinkTwitterToWallet) {
      linkTwitterToWallet(router.asPath)
        .then((result: any) => {
          console.log(result)
        })
        .catch((e: any) => {
          console.log(e)
          throw new Error('Something went wrong linking twitter to wallet: ', e)
        })
    } else if (canLinkWalletToTwitter) {
      linkWalletToTwitter()
        .then((result: any) => {
          console.log(result)
        })
        .catch((e: any) => {
          console.log(e)
          throw new Error('Something went wrong linking wallet to twitter: ', e)
        })
    }
  }

  return (
    <ConnectButton.Custom>
      {({ account, chain, openConnectModal, mounted }) => {
        // Andrew: We need to know that the user signed the message (and wrote to the db), and we see data in gobblerAuth.
        const isSignedIn = user && user.wallet_address && account

        return (
          <>
            {(() => {
              const profileName =
                account?.ensName || getShortenedAddress(account?.address || '') || 'Unconnected'

              return (
                <>
                  {/* {isMobile && (
                    <Link href={{ pathname: '/about' }} passHref>
                      <PrimaryButton
                        as={'a'}
                        // TODO: Confirm if this new style is a one-off for the PrimaryButton, or if we can change the PrimaryButtond default style.
                        style={{ backgroundColor: 'white' }}
                        buttonSize="small"
                        type="button"
                      >
                        About
                      </PrimaryButton>
                    </Link>
                  )} */}

                  {true && (
                    <Link href={{ pathname: '/gobbler/mint' }} passHref>
                      <PrimaryButton
                        as={'a'}
                        // TODO: Confirm if this new style is a one-off for the PrimaryButton, or if we can change the PrimaryButtond default style.
                        style={{
                          borderRadius: 30,
                          width: 'fit-content',
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 10,
                          paddingBottom: 10,
                          // height: 'auto',
                          marginRight: 8,
                          height: 'fit-content',
                          // marginTop: 10,
                          // marginBottom: 10,
                          borderWidth: 3,
                          boxShadow:
                            '0px 24px 32px rgba(0, 0, 0, 0.1), 0px 12px 16px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.06), inset 0px 4px 4px rgba(255, 255, 255, 0.25)',
                        }}
                        buttonType={'success'}
                        buttonSize="small"
                        type="button"
                      >
                        Mint
                      </PrimaryButton>
                    </Link>
                  )}

                  {showDrawButton && (
                    <Link href={{ pathname: '/draw' }} passHref>
                      <PrimaryButton
                        as={'a'}
                        // TODO: Confirm if this new style is a one-off for the PrimaryButton, or if we can change the PrimaryButtond default style.
                        style={{
                          borderRadius: 30,
                          width: 'fit-content',
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 10,
                          paddingBottom: 10,
                          height: 'auto',
                          marginTop: 10,
                          marginBottom: 10,
                          borderWidth: 3,
                          boxShadow:
                            '0px 24px 32px rgba(0, 0, 0, 0.1), 0px 12px 16px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.06), inset 0px 4px 4px rgba(255, 255, 255, 0.25)',
                        }}
                        buttonType={isDrawHeader ? 'primary' : 'secondary'}
                        buttonSize="small"
                        type="button"
                      >
                        Draw
                      </PrimaryButton>
                    </Link>
                  )}
                  {!isDrawHeader && !isSignedIn && (
                    <PrimaryButton
                      onPress={() => signInWithWallet()}
                      style={{
                        marginLeft: 8,
                        borderRadius: 30,
                        width: 'fit-content',
                        paddingLeft: 20,
                        paddingRight: 20,
                        paddingTop: 10,
                        paddingBottom: 10,
                        height: 'auto',
                        marginTop: 10,
                        marginBottom: 10,
                        borderWidth: 3,
                        boxShadow:
                          '0px 24px 32px rgba(0, 0, 0, 0.1), 0px 12px 16px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.06), inset 0px 4px 4px rgba(255, 255, 255, 0.25)',
                      }}
                      buttonType={isDrawHeader ? 'secondary' : 'primary'}
                      buttonSize="small"
                      type="button"
                    >
                      Connect
                    </PrimaryButton>
                  )}

                  {!isDrawHeader && isSignedIn && (
                    <>
                      <UserAvatarSpacer />
                      <UserAvatarMenuButon
                        label="Menu Actions"
                        onAction={(key) => {
                          switch (key) {
                            case 'connect': {
                              signInWithWallet()
                              break
                            }
                            case 'profile':
                              if (!isSignedIn) {
                                return
                              }
                              router.push({
                                pathname: '/user/[userId]',
                                query: {
                                  userId: account.address,
                                },
                              })
                              break
                            case 'drafts':
                              if (!isSignedIn) {
                                return
                              }
                              router.push({
                                pathname: '/drafts',
                                query: {
                                  userId: account.address,
                                },
                              })
                              break
                            case 'factory':
                              if (!isSignedIn) {
                                return
                              }
                              router.push({
                                pathname: '/factory',
                                query: {
                                  userId: account.address,
                                },
                              })
                              break
                            case 'disconnect':
                              handleSignOut()
                              break
                            default:
                              break
                          }
                        }}
                      >
                        {isSignedIn && (
                          <Item textValue="Profile" key="profile">
                            <PDesktop style={{ fontWeight: '500' }}>
                              Profile ({profileName})
                            </PDesktop>
                          </Item>
                        )}
                        {/* {account && (
                          <Item textValue="Wallet: Connected" key="wallet-connected">
                            <PDesktop style={{ fontWeight: '500' }}>{'Wallet: Connected'}</PDesktop>
                          </Item>
                        )} */}
                        {isSignedIn && (
                          <Item textValue="Twitter: Not Linked" key="twitter-connected">
                            <div
                              onClick={handleLinkTwitter}
                              style={{ width: '100%', height: '100%' }}
                            >
                              <PDesktop style={{ fontWeight: '500' }}>
                                {'Twitter: ' +
                                  (isTwitterConnectedAndAuthed ? 'Connected' : 'Not Connected')}
                              </PDesktop>
                            </div>
                          </Item>
                        )}
                        {isSignedIn && (
                          <Item textValue="Drafts" key="drafts">
                            <PDesktop style={{ fontWeight: '500' }}>Drafts</PDesktop>
                          </Item>
                        )}
                        {/* {account && (
                          <Item textValue="Factory" key="factory">
                            <PDesktop style={{ fontWeight: '500' }}>Factory</PDesktop>
                          </Item>
                        )} */}
                        {isSignedIn && (
                          <Item textValue="Copy wallet address" key="copy">
                            <PDesktop style={{ fontWeight: '500' }}>Copy Address</PDesktop>
                          </Item>
                        )}
                        {isSignedIn && (
                          <Item textValue="Disconnect wallet" key="disconnect">
                            <div style={{}}>
                              <PDesktop style={{ fontWeight: '500' }}>Log out</PDesktop>
                            </div>
                          </Item>
                        )}
                        {!isSignedIn && (
                          <Item textValue="Connect Wallet" key="connect">
                            <PDesktop style={{ fontWeight: '500' }}>Connect Wallet</PDesktop>
                          </Item>
                        )}
                      </UserAvatarMenuButon>
                    </>
                  )}
                </>
              )
            })()}
          </>
        )
      }}
    </ConnectButton.Custom>
  )
}

const UserAvatarSpacer = styled.div`
  width: 24px;
`

export default HeaderConnectButton
